/* Generated by restful-react */

import React from 'react'
import { Get, GetProps, Mutate, MutateProps, useGet, UseGetProps, useMutate, UseMutateProps } from 'restful-react'
/**
 * Error cuando una petición no es completada
 */
export interface ApiError {
  /**
   * Codigo de error
   */
  error_code: number
  /**
   * Mensaje de error
   */
  message_error: string
}

/**
 * Respuesta de una encuesta y sus identificadores
 */
export interface SurveyResponse {
  /**
   * Fecha de realización de la encuesta
   */
  date?: string
  /**
   * guid
   */
  survey_id: string
  /**
   * key de la entidad
   */
  entity_key: string
  /**
   * Nombre Completo
   */
  given_name: string
  /**
   * Survey Response model
   */
  survey_response: string
}

/**
 * Definición de la encuesta y sus identificadores
 */
export interface SurveyDefinition {
  /**
   * Entity ID
   */
  entity_id: number
  /**
   * key de la entidad
   */
  entity_key: string
  /**
   * Name Entity
   */
  entity_name: string
  /**
   * json string survey definition
   */
  survey_definition: string
  /**
   * Guid
   */
  survey_id: string
}

/**
 * Token de autenticación para la sesión
 */
export interface TokenAuth {
  /**
   * JWT
   */
  token: string
}

/**
 * Contiene los datos del usuario que ha iniciado sesión.
 */
export interface SessionToken {
  /**
   * Nombre de Usuario
   */
  user_name: string
  /**
   * Nombre Dado
   */
  given_name: string
  /**
   * ID Entidad
   */
  entity_id: string
  /**
   * Nombre Entidad
   */
  entity_name: string
  /**
   * Key de la entidad
   */
  entity_key: string
  /**
   * id en formato guid para identificar que el token no ha sido usado mas de una vez.
   */
  token_id: string
  /**
   * id de la encuesta en formato guid
   */
  survey_id: string
}

export type PostValidateProps = Omit<MutateProps<SessionToken, void, void, TokenAuth, void>, 'path' | 'verb'>

/**
 * Valida el JWT
 *
 * Valida el JWT y retorna cookie de autenticación
 */
export const PostValidate = (props: PostValidateProps) => (
  <Mutate<SessionToken, void, void, TokenAuth, void> verb='POST' path={`/api/v1/validate`} {...props} />
)

export type UsePostValidateProps = Omit<UseMutateProps<SessionToken, void, void, TokenAuth, void>, 'path' | 'verb'>

/**
 * Valida el JWT
 *
 * Valida el JWT y retorna cookie de autenticación
 */
export const usePostValidate = (props: UsePostValidateProps) =>
  useMutate<SessionToken, void, void, TokenAuth, void>('POST', `/api/v1/validate`, props)

export interface GetSurveyPathParams {
  /**
   * id de la encuesta surveyId
   *
   */
  id: string
}

export type GetSurveyProps = Omit<GetProps<SurveyDefinition, ApiError, void, GetSurveyPathParams>, 'path'> &
  GetSurveyPathParams

/**
 * SurveyDefinitions
 *
 * Obtiene la configuración de una encuesta basado en el identificador.
 */
export const GetSurvey = ({ id, ...props }: GetSurveyProps) => (
  <Get<SurveyDefinition, ApiError, void, GetSurveyPathParams> path={`/api/v1/survey/${id}`} {...props} />
)

export type UseGetSurveyProps = Omit<UseGetProps<SurveyDefinition, ApiError, void, GetSurveyPathParams>, 'path'> &
  GetSurveyPathParams

/**
 * SurveyDefinitions
 *
 * Obtiene la configuración de una encuesta basado en el identificador.
 */
export const useGetSurvey = ({ id, ...props }: UseGetSurveyProps) =>
  useGet<SurveyDefinition, ApiError, void, GetSurveyPathParams>(
    (paramsInPath: GetSurveyPathParams) => `/api/v1/survey/${paramsInPath.id}`,
    { pathParams: { id }, ...props }
  )

export interface GetSurveyResultsQueryParams {
  /**
   * Filtro por mes
   */
  month: number
  /**
   * Filtro por año
   */
  year: number
}

export interface GetSurveyResultsPathParams {
  /**
   * id de la encuesta surveyId
   */
  id: string
}

export type GetSurveyResultsProps = Omit<
  GetProps<SurveyResponse[], void, GetSurveyResultsQueryParams, GetSurveyResultsPathParams>,
  'path'
> &
  GetSurveyResultsPathParams

/**
 * Descargar los resultados
 *
 * Descargar los resultados de las encuestas por ID y rango de fechas
 */
export const GetSurveyResults = ({ id, ...props }: GetSurveyResultsProps) => (
  <Get<SurveyResponse[], void, GetSurveyResultsQueryParams, GetSurveyResultsPathParams>
    path={`/api/v1/survey/${id}/results`}
    {...props}
  />
)

export type UseGetSurveyResultsProps = Omit<
  UseGetProps<SurveyResponse[], void, GetSurveyResultsQueryParams, GetSurveyResultsPathParams>,
  'path'
> &
  GetSurveyResultsPathParams

/**
 * Descargar los resultados
 *
 * Descargar los resultados de las encuestas por ID y rango de fechas
 */
export const useGetSurveyResults = ({ id, ...props }: UseGetSurveyResultsProps) =>
  useGet<SurveyResponse[], void, GetSurveyResultsQueryParams, GetSurveyResultsPathParams>(
    (paramsInPath: GetSurveyResultsPathParams) => `/api/v1/survey/${paramsInPath.id}/results`,
    { pathParams: { id }, ...props }
  )

export interface PostSurveyPathParams {
  /**
   * id de la encuesta surveyId
   */
  id: string
}

export type PostSurveyProps = Omit<
  MutateProps<SurveyResponse, ApiError, void, SurveyResponse, PostSurveyPathParams>,
  'path' | 'verb'
> &
  PostSurveyPathParams

/**
 * SurveyResponse
 *
 * Enviar resultados de una encuesta basado en el identificador.
 */
export const PostSurvey = ({ id, ...props }: PostSurveyProps) => (
  <Mutate<SurveyResponse, ApiError, void, SurveyResponse, PostSurveyPathParams>
    verb='POST'
    path={`/api/v1/survey/${id}/results`}
    {...props}
  />
)

export type UsePostSurveyProps = Omit<
  UseMutateProps<SurveyResponse, ApiError, void, SurveyResponse, PostSurveyPathParams>,
  'path' | 'verb'
> &
  PostSurveyPathParams

/**
 * SurveyResponse
 *
 * Enviar resultados de una encuesta basado en el identificador.
 */
export const usePostSurvey = ({ id, ...props }: UsePostSurveyProps) =>
  useMutate<SurveyResponse, ApiError, void, SurveyResponse, PostSurveyPathParams>(
    'POST',
    (paramsInPath: PostSurveyPathParams) => `/api/v1/survey/${paramsInPath.id}/results`,
    { pathParams: { id }, ...props }
  )
