import React, { FunctionComponent, useState } from 'react'
import useGetResults from '../hooks/useGetResults'
import useGetUserAgent from '../hooks/useGetUserAgent'
import DownloadForm from './DownloadForm'

interface DownloadResultsProps {
  idSurvey: string
}

export const useDownloadResults = (idSurvey: string) => {
  const [date, setDate] = useState('')

  const getResults = useGetResults(idSurvey, date)

  const isChrome = useGetUserAgent('Chrome')

  return { getResults, date, setDate, isChrome }
}

const DownloadResults: FunctionComponent<DownloadResultsProps> = ({ idSurvey }) => {
  const props = useDownloadResults(idSurvey)

  return (
    <div data-testid='downloadForm'>
      <DownloadForm {...props} />
    </div>
  )
}

export default DownloadResults
