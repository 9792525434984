import { useState } from 'react'
import { SurveyResponse, usePostSurvey } from '../api/api'
import { useSessionContext } from '../contexts/SessionProvider'

const usePostSurveyResult = () => {
  const [isCompleted, setIscompleted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const { session } = useSessionContext()
  const postResult = usePostSurvey({ id: session?.survey_id as string })

  const postSurveyResult = (result: {}) => {
    postResult
      .mutate({
        survey_id: session?.survey_id,
        entity_key: session?.entity_key,
        given_name: session?.given_name,
        survey_response: JSON.stringify(result)
      } as SurveyResponse)
      .then(() => {
        setIscompleted(true)
      })
      .catch(() => {
        setErrorMessage('Ha ocurrido un error al enviar resultado de la encuesta')
      })
  }

  return { postSurveyResult, loading: postResult.loading, isCompleted, errorMessage }
}

export default usePostSurveyResult
