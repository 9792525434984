import { useEffect, useState } from 'react'
import { useGetSurvey } from '../api/api'

const useSurveyDefinitionApi = (idSurvey: string) => {
  const [surveyDefinitionObj, setSurveyDefinitionObj] = useState({})
  const [errorMessage, setErrorMessage] = useState('')

  const { data, error, loading, refetch } = useGetSurvey({ id: idSurvey, lazy: true })

  useEffect(() => {
    if (data) {
      const surveyParsed = JSON.parse(data.survey_definition)
      setSurveyDefinitionObj(surveyParsed)
    }
  }, [data])

  useEffect(() => {
    if (idSurvey) {
      refetch()
    }
  }, [idSurvey])

  useEffect(() => {
    if (error !== null) {
      setErrorMessage('Ocurrió un error en el servidor')
    }
  }, [error])

  return { surveyDefinitionObj, error: errorMessage, loading }
}

export default useSurveyDefinitionApi
