import { Trans } from '@lingui/macro'
import React, { FunctionComponent } from 'react'
import { Form } from 'react-formio'

export interface SurveyFormProps {
  data: {}
  error: string | null
  isLoading: boolean
  postResult: {
    postSurveyResult: (result: {}) => void
    loading: boolean
    isCompleted: boolean
    errorMessage: string
  }
}

const SurveyForm: FunctionComponent<SurveyFormProps> = ({ isLoading, data, error, postResult }) => {
  const handleSubmit = (result: {}) => {
    postResult.postSurveyResult(result)
  }

  return error !== '' ? (
    <h4 data-testid='cannot-load-survey'>
      <Trans>No se pudo cargar la encuesta</Trans>
    </h4>
  ) : isLoading ? (
    <h1 data-testid='loading'>
      <Trans>Cargando...</Trans>
    </h1>
  ) : (
    <div className='container' data-testid='survey_definition'>
      {postResult.errorMessage !== '' ? (
        <h4 data-testid='cannot-send-result'>{postResult.errorMessage}</h4>
      ) : postResult.loading ? (
        <h4 data-testid='sending-result'>
          <Trans>Enviando respuesta de la encuesta</Trans>
        </h4>
      ) : !postResult.isCompleted ? (
        <Form form={data} onSubmit={handleSubmit} />
      ) : (
        <h3 data-testid='survey-sent-succesful'>
          <Trans>Encuesta completada satisfactoriamente</Trans>
        </h3>
      )}
    </div>
  )
}

export default SurveyForm
