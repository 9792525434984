import { Parser, transforms } from 'json2csv'
import * as _ from 'lodash'
import { SurveyResponse } from '../api/api'

const flattenFn = transforms.flatten({ separator: '_' })

const json2csvParser = new Parser({ transforms: [flattenFn] })

const transformer = (results: SurveyResponse[]) => {
  const responses = results.map(r => _.omit((r.survey_response as any).data, ['metadata', 'state']))
  return json2csvParser.parse(responses)
}

export default transformer
