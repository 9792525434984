import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { messages as messages_es } from './locales/es/messages'

i18n.load('es', messages_es)
i18n.activate('es')

ReactDOM.render(
  <I18nProvider i18n={i18n}>
    <App baseURL={'/'} />
  </I18nProvider>,
  document.getElementById('root')
)
