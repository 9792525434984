import { Trans } from '@lingui/macro'
import React, { FunctionComponent, MouseEventHandler, useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import MonthPickerInput from 'react-month-picker-input'

// tslint:disable-next-line: no-var-requires
require('react-month-picker-input/dist/react-month-picker-input.css')

export interface DownloadFormProps {
  getResults: {
    surveyResults: string | null
    errorMessage: string
    loading: boolean
  }
  date: string
  isChrome: boolean
  setDate: (msg: string) => void
}

const DownloadForm: FunctionComponent<DownloadFormProps> = ({ getResults, date, setDate, isChrome }) => {
  const [readyData, setReadyData] = useState(false)
  const [isEmpty, setIsEmpty] = useState(true)

  useEffect(() => {
    setReadyData(date !== '' && getResults.errorMessage === '' && !getResults.loading)
  }, [date, getResults.loading, getResults.errorMessage])

  useEffect(() => {
    setIsEmpty(getResults.surveyResults === '')
  }, [getResults.surveyResults])

  const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value)
  }

  const handleChangeFirefox = (selectedYear: number, selectedMonth: number) => {
    setDate(`${selectedYear}-${selectedMonth}`)
  }

  const handleClick = (event: MouseEventHandler<HTMLAnchorElement>) => {
    return !isEmpty
  }

  return (
    <div className='row justify-content-center align-items-center'>
      <div className='col-sm-6'>
        <form>
          <h2>
            <Trans>Descarga de Resultados</Trans>
          </h2>
          {getResults.errorMessage && (
            <div className='alert alert-danger' role='alert'>
              {getResults.errorMessage}
            </div>
          )}
          {isEmpty && readyData && (
            <div className='alert alert-info' role='alert'>
              <Trans>No hay resultados en el mes seleccionado</Trans>
            </div>
          )}
          <div className='row g-3'>
            <div className='col-auto'>
              <label htmlFor='month'>
                <Trans>Mes</Trans>
              </label>
              {isChrome ? (
                <input type='month' className='form-control' id='month' onChange={handleChange()} />
              ) : (
                <div data-testid='input-firefox'>
                  <MonthPickerInput
                    className={'form-control'}
                    onChange={handleChangeFirefox}
                    lang={'es'}
                    closeOnSelect={true}
                    data-testid='input-firefox'
                  />
                </div>
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col-auto my-3'>
              <CSVLink
                data={getResults.surveyResults || ''}
                className={`btn btn-primary btn-principal ${!readyData || isEmpty ? 'disabled' : ''}`}
                filename={`resultados-encuesta-${date}.csv`}
                onClick={handleClick}
              >
                {getResults.loading ? (
                  <div>
                    <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />
                    <span className='ml-1'>
                      <Trans>Obteniendo resultados...</Trans>
                    </span>
                  </div>
                ) : (
                  <Trans>Descargar</Trans>
                )}
              </CSVLink>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default DownloadForm
