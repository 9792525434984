import React, { FC } from 'react'
import usePostSurveyResult from '../hooks/usePostSurveyResult'
import useSurveyDefinitionApi from '../hooks/useSurveyDefinitionApi'
import SurveyForm from './SurveyForm'

interface SurveyDefinitionProps {
  idSurvey: string
}

const SurveyDefinition: FC<SurveyDefinitionProps> = ({ idSurvey }) => {
  const { surveyDefinitionObj, error, loading } = useSurveyDefinitionApi(idSurvey)
  const postResult = usePostSurveyResult()

  return (
    <div data-testid='surveyDefinition'>
      <SurveyForm data={surveyDefinitionObj} error={error} isLoading={loading} postResult={postResult} />
    </div>
  )
}

export default SurveyDefinition
