import { Trans } from '@lingui/macro'
import React, { Fragment, FunctionComponent } from 'react'
import { useSessionContext } from '../contexts/SessionProvider'
import SurveyDefinition from './SurveyDefinition'

const Main: FunctionComponent = () => {
  const { session, error, loading } = useSessionContext()

  return (
    <Fragment>
      <div className='container my-4'>
        {error !== '' ? (
          <h4 data-testid='errorMessage'>{error}</h4>
        ) : loading ? (
          <h4 data-testid='validatingMessage'>
            <Trans>Validando permisos...</Trans>
          </h4>
        ) : (
          <SurveyDefinition idSurvey={session?.survey_id as string} />
        )}
      </div>
    </Fragment>
  )
}
export default Main
