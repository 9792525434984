import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react'
import { SessionToken, usePostValidate } from '../api/api'
import useQueryParams from '../hooks/useQueryParams'

interface SessionContextModel {
  session: SessionToken | null
  error: string
  loading: boolean
}

const initianState: SessionContextModel = {
  session: null,
  error: '',
  loading: false
}

export interface SessionProviderProps {
  children: ReactNode
}

const SessionContext = createContext<SessionContextModel>(initianState)

export const useSessionContext = () => useContext(SessionContext)

export const SessionProvider: FC<SessionProviderProps> = ({ children }) => {
  const [session, setSession] = useState(null as SessionToken | null)
  const [error, setError] = useState('')
  const token = useQueryParams('token')

  const postValidate = usePostValidate({})

  useEffect(() => {
    postValidate
      .mutate({ token })
      .then((res: SessionToken) => {
        setSession(res)
      })
      .catch(() => {
        setError('Error al validar el token')
      })
  }, [])

  return (
    <SessionContext.Provider value={{ session, error, loading: postValidate.loading }}>
      {children}
    </SessionContext.Provider>
  )
}
