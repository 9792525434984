/*eslint-disable*/ export const messages = {
  'Cargando...': 'Cargando...',
  'Descarga de Resultados': 'Descarga de Resultados',
  Descargar: 'Descargar',
  'Encuesta completada satisfactoriamente': 'Encuesta completada satisfactoriamente',
  'Enviando respuesta de la encuesta': 'Enviando respuesta de la encuesta',
  Mes: 'Mes',
  'No hay resultados en el mes seleccionado': 'No hay resultados en el mes seleccionado',
  'No se pudo cargar la encuesta': 'No se pudo cargar la encuesta',
  'Obteniendo resultados...': 'Obteniendo resultados...',
  'Validando permisos...': 'Validando permisos...'
}
