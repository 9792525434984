import React, { FunctionComponent } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { RestfulProvider } from 'restful-react'
import Main from './components/Main'
import MainDownload from './components/MainDownload'
import { SessionProvider } from './contexts/SessionProvider'
import './styles/custom.css'

interface AppProps {
  baseURL: string
}

const App: FunctionComponent<AppProps> = ({ baseURL }) => {
  if (process.env.NODE_ENV === 'development') {
    baseURL = 'http://127.0.0.1:4010'
  }

  return (
    <RestfulProvider base={baseURL}>
      <Router>
        <Switch>
          <SessionProvider>
            <Route exact path={'/'}>
              <Main />
            </Route>
            <Route path={'/download'}>
              <MainDownload />
            </Route>
          </SessionProvider>
        </Switch>
      </Router>
    </RestfulProvider>
  )
}

export default App
