import { useEffect, useState } from 'react'
import { useGetSurveyResults } from '../api/api'
import transformer from '../transformer/transformer'

export const getYearAndMonth = (date: string) => {
  const dateArray = date.split('-')
  const month = dateArray[1]
  const year = dateArray[0]
  return { month: parseInt(month, 10), year: parseInt(year, 10) }
}

const useGetResults = (idSurvey: string, date: string) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [surveyResults, setSurveyResults] = useState<string | null>(null)

  const dateObj = getYearAndMonth(date)

  const getResults = useGetSurveyResults({
    id: idSurvey,
    lazy: date === '',
    queryParams: {
      year: dateObj.year,
      month: dateObj.month
    }
  })

  useEffect(() => {
    if (getResults.data && getResults.data.length > 0) {
      setSurveyResults(transformer(getResults.data))
    } else {
      setSurveyResults('')
    }
  }, [getResults.data])

  useEffect(() => {
    setSurveyResults(null)
    setErrorMessage('')
  }, [date])

  useEffect(() => {
    if (getResults.error !== null) {
      setErrorMessage('Ocurrió un error en el servidor')
    }
  }, [getResults.error])

  return { surveyResults, errorMessage, loading: getResults.loading }
}

export default useGetResults
